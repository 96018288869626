.c-sidebar-item {
  margin-bottom: 4px;

  .sidebar-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 4px 8px;
    margin-bottom: 4px;
    width: 208px;
    font-weight: 600;

    &:hover {
      border-radius: 8px;
      background-color: rgba(229, 231, 235, 1);
      color: rgba(59, 130, 246, 1);
    }

    &--active {
      border-radius: 8px;
      background-color: rgba(219, 234, 254, 1);
      color: rgba(59, 130, 246, 1);
      font-weight: 600;
    }

    &__icon {
      width: 24px;
      height: 24px;
    }

    &__name {
      font-size: 14px;
      margin-left: 8px;
      padding: 4px 0;
    }

    .left {
      display: flex;
      align-items: center;
    }

    .right {
      display: flex;
      align-items: center;
    }

    &__sub-item-wrapper {
      // padding: 4px 0;
      border-radius: 8px;
      background-color: rgb(239, 241, 245);
      color: rgba(59, 130, 246, 1);

      @-webkit-keyframes fadeIn {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }

      @keyframes fadeIn {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }

      -webkit-animation: fadeIn 1s;
      animation: fadeIn 1s;
    }

    &__sub-item {
      display: block;
      align-items: center;
      cursor: pointer;
      padding: 6px 0 6px 40px;
      color: var(--color-black);
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;
      user-select: none;

      &:hover {
        border-radius: 8px;
        background-color: rgba(229, 231, 235, 1);
        color: rgba(59, 130, 246, 1);
      }

      &--active {
        border-radius: 8px;
        background-color: rgba(219, 234, 254, 1);
        color: rgba(59, 130, 246, 1);
        font-weight: 600;
      }
    }
  }
}
