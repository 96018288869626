.c-account-dropdown {
  border-radius: 16px;

  .label {
    font-size: 14px;
    font-weight: 600;
  }

  .user-info {
    display: flex;
    align-items: center;
    gap: 12px;
    min-width: 250px;

    .avatar {
      width: 60px;
      height: 60px;
      flex-shrink: 0;
      overflow: hidden;
      border-radius: 50%;
      border: var(--border-2);
      color: var(--color-neutral-1);
      box-sizing: border-box;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .right {
      display: flex;
      flex-direction: column;

      .name {
        font-size: 14px;
        font-weight: 600;
        color: var(--color-black);
      }

      .username {
        font-weight: 300;
        font-size: 12px;
        line-height: 20px;
        color: #767676;
      }

      .position {
        font-weight: 300;
        font-size: 12px;
        line-height: 20px;
        color: #767676;
      }
    }
  }

  .ant-dropdown-menu-item-group-title {
    font-size: 12px !important;
    color: var(--color-black) !important;
  }

  .ant-dropdown-menu-item {
    border-radius: 8px !important;
    padding: 8px !important;
  }
}
