@import "@/styles/base/responsive";

.c-ticket-options {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .description {
    white-space: pre-line;
  }

  .ant-radio-wrapper {
    width: 100%;
    min-height: 100px;
    border-radius: 6px;
    border: 2px solid rgba(5, 5, 5, 0.06);
    padding: 10px;
    margin-inline-end: 0;

    @include Responsive(LG) {
      width: 49.2%;
    }

    .option {
      display: flex;
      flex-direction: column;
    }
  }
}
