@import "@/styles/base/responsive";

.p-my-ticket {
  display: flex;
  justify-content: center;
  min-height: 240px;

  .inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    padding: 10px;
    border-radius: 6px;

    @include Responsive(LG) {
      width: 700px;
    }

    .status {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    .ant-typography {
      margin-bottom: 0 !important;
    }

    .ant-card {
      width: 100%;

      @include Responsive(MD) {
        width: 49.2%;
      }
    }

    .ant-empty {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .receive-ticket {
      display: flex;
      gap: 4px;
      margin-top: 4px;
    }
  }
}
