.c-view-send-ticket-log {
  .field-image {
    display: flex;
    align-items: center;

    img {
      border-radius: 6px;
      width: 112px;
    }

    .ant-image-mask {
      border-radius: 6px;
    }
  }

  .ant-form-item-control-input-content {
    color: red !important;
  }
}
