.c-artist-list-wrap {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .ant-avatar-group .info {
    display: none !important;
  }

  .avatar {
    width: 32px;
    height: 32px;
  }

  .single-artist {
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
  }

  .action-time {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    width: max-content;

    .clock-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      background-color: var(--color-primary);
      border: var(--border-shape-2);
      flex-shrink: 0;

      span > svg {
        fill: var(--color-neutral-1);
        width: 24px;
        height: 24px;
      }
    }
  }
}
