@import "@/styles/base/responsive";

.p-meeting-info {
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100%;

  .register-btn-mobile {
    position: fixed;
    bottom: 20px;
    z-index: 100;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 8px 10px -6px rgba(0, 0, 0, 0.1);

    @include Responsive(MD) {
      display: none;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    border-radius: var(--border-radius);
    width: 100vw;
    padding-bottom: 50px;

    @include Responsive(MD) {
      width: 800px;
    }

    .body {
      padding: 0 20px;

      @include Responsive(MD) {
        padding: 0;
      }

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .event-title {
          font-weight: 600;
          font-size: 20px;
          padding: 12px 0;

          @include Responsive(MD) {
            font-size: 28px;
          }
        }

        .register-btn {
          display: none;

          @include Responsive(MD) {
            display: block;
          }
        }
      }

      .time,
      .location {
        font-size: 16px;
        display: flex;
        align-items: center;
        gap: 6px;
        margin-bottom: 4px;

        .icon {
          display: flex;
          align-items: center;
          width: 20px;
        }
      }

      .tabs {
        margin-top: 10px;

        .ant-tabs-tab-btn {
          font-size: 16px;
        }

        .space-img {
          .ant-image-img,
          .ant-image-mask {
            margin-bottom: 10px;
          }
        }
      }

      .guideline {
        width: 100% !important;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        width: 300px; /* Điều chỉnh kích thước container theo ý muốn */
        height: 300px; /* Điều chỉnh kích thước container theo ý muốn */
        gap: 10px; /* Độ rộng khoảng cách giữa các block */

        .ant-image {
          width: 100% !important;
        }
      }
    }
  }
}
