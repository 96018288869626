@import "base/all";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "base/responsive";

* {
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
}

body {
  color: var(--body-color);
  background: var(--body-bg);
  font-size: 14px;
}

a {
  color: inherit;
  text-decoration: none;
}

body,
button,
p,
div,
span,
small {
  font-family: Inter, Arial, Helvetica, sans-serif;
}
