.t-payment-tab {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .qr-code {
    width: 300px;
  }

  .download-qr {
    margin-top: 10px;
  }

  .bank-info {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 4px;

    .ant-typography {
      margin-bottom: -1px;
    }
  }

  .note {
    margin-top: 14px;
    margin-bottom: 10px;
    color: red;
    font-weight: 600;
  }
}
