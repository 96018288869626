.p-login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;

  .login-form {
    display: flex;
    flex-direction: column;
    min-width: 340px;
    border-radius: var(--border-radius);
    padding: 20px;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 8px 10px -6px rgba(0, 0, 0, 0.1);
    background-color: var(--color-neutral-1);

    .header {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .title {
        font-size: 28px;
        font-weight: 600;
      }
    }

    .body {
      margin-top: 20px;

      .login-btn,
      .forget-btn {
        width: 100%;
      }
    }
  }
}
