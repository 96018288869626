.c-countdown-timer {
  .countdown-bar {
    display: flex;
    gap: 10px;
  }

  .time {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2px;
    border-radius: 6px;
    width: 50px;
    height: 50px;
    border: 1px solid var(--color-primary);

    .time-name {
      font-size: 12px;
      color: var(--color-white);
    }

    .time-value {
      font-size: 18px;
      font-weight: 600;
      color: var(--color-white);
    }
  }
}
