@import "@/styles/base/responsive";

.c-sidebar {
  display: none;
  flex-direction: column;
  overflow-y: auto;
  width: 250px;
  height: 100vh;
  background-color: #f6f8f9;
  border-right: var(--border-layout-1);
  padding: 0 12px;
  box-sizing: border-box;

  @include Responsive(LG) {
    display: flex;
  }

  .top {
    height: 64px;
    text-align: center;
    padding: 12px 0;

    .user-info {
      display: flex;
      align-items: center;
      gap: 10px;
      .avatar {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        overflow: hidden;
        flex-shrink: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;

        .name {
          font-weight: 700;
        }
      }
    }
  }

  .menu {
    overflow-y: auto;
    flex: 1;
  }

  .bottom {
    display: flex;
    flex-direction: column;
    padding: 8px 0;
    font-size: 12px;
    font-weight: 700;
  }
}
