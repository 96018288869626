.p-404 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .ant-result-extra {
    display: flex !important;
    justify-content: center !important;
  }
}
