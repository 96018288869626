.c-button {
  display: flex;
  align-items: center;
  justify-content: center;

  &--success {
    background: var(--button-success-bg-color);
    color: var(--button-success-color);

    &:hover,
    &:focus {
      background: var(--button-success-hover-bg-color) !important;
      color: var(--button-success-hover-color) !important;
    }
  }

  &--danger {
    background: var(--button-danger-bg-color);
    color: var(--button-danger-color);

    &:hover,
    &:focus {
      background: var(--button-danger-hover-bg-color) !important;
      color: var(--button-danger-hover-color) !important;
    }
  }

  &--warning {
    background: var(--button-warning-bg-color);
    color: var(--button-warning-color);

    &:hover,
    &:focus {
      background: var(--button-warning-hover-bg-color) !important;
      color: var(--button-warning-hover-color) !important;
    }
  }
}
