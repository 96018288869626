.user-info-wrap {
  width: max-content;

  .user-show {
    display: flex;
    align-items: center;
    cursor: pointer;

    .avatar {
      border-radius: 50%;
      overflow: hidden;
      border: var(--border-shape-2);
      background-color: var(--color-neutral-1);
    }

    .info {
      display: flex;
      flex-direction: column;
    }
  }
}

.user-hover {
  display: flex;
  gap: 8px;

  .avatar {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    overflow: hidden;
    border: var(--border-shape-2);
    background-color: var(--color-neutral-1);
  }

  .info {
    display: flex;
    flex-direction: column;
  }
}
