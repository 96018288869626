:root {
  --color-neutral-1: #ffffff;
  --color-neutral-3: #f5f5f5;
  --color-neutral-4: #f0f0f0;
  --color-neutral-5: #d9d9d9;
  --color-neutral-6: #bfbfbf;
  --color-neutral-7: #8c8c8c;
  --color-neutral-8: #595959;
  --color-neutral-9: #434343;

  --color-green-1: #28a745;
  --color-green-2: #218838;
  --color-green-3: #1e7e34;

  --color-yellow-1: #ffc107;
  --color-yellow-2: #e0a800;
  --color-yellow-3: #d39e00;

  --color-red-0: #ff4d4f;
  --color-red-1: #dc3545;
  --color-red-2: #c82333;
  --color-red-3: #bd2130;

  --color-text-disable: #d3d3d3;

  --color-alabaster-1: #ecf3e5;

  --body-color: #434343;
  --character-primary-inverse: #ffffff;

  --button-success-bg-color: var(--color-green-1);
  --button-success-color: var(--color-neutral-1);
  --button-success-hover-bg-color: var(--color-green-2);
  --button-success-hover-color: var(--color-neutral-1);

  --button-danger-bg-color: var(--color-red-1);
  --button-danger-color: var(--color-neutral-1);
  --button-danger-hover-bg-color: var(--color-red-2);
  --button-danger-hover-color: var(--color-neutral-1);

  --button-warning-bg-color: var(--color-yellow-1);
  --button-warning-color: var(--color-neutral-1);
  --button-warning-hover-bg-color: var(--color-yellow-2);
  --button-warning-hover-color: var(--color-neutral-1);

  --color-primary: #1677ff;

  --border-layout-1: 1px solid rgb(229, 231, 235);
  --border-layout-2: 2px solid rgb(229, 231, 235);

  --border-shape-1: 1px solid var(--color-neutral-1);
  --border-shape-2: 2px solid var(--color-neutral-1);

  --border-radius: 6px;
}
