.artist-info-wrap {
  width: max-content;

  .artist-show {
    display: flex;
    align-items: center;
    cursor: pointer;

    .avatar {
      border-radius: 50%;
      overflow: hidden;
      border: var(--border-shape-1);
      background-color: var(--color-neutral-1);

      img {
        width: 100%;
        object-fit: cover;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
    }
  }
}

.artist-hover {
  display: flex;
  gap: 8px;

  .avatar {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    overflow: hidden;
    border: var(--border-shape-2);
    background-color: var(--color-neutral-1);

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
  }
}
