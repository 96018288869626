.ant-typography-ellipsis {
  white-space: pre-line;
}

.c-event-popup {
  .ant-modal-confirm-btns {
    display: flex;

    button {
      width: 100%;
    }
  }
}
