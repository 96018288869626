.no-select {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.pagination-table {
  display: flex;
  justify-content: flex-end;
  padding: 16px 0;
}

.pre-line {
  white-space: pre-line;
}

.c-preview-modal {
  .react-player {
    width: auto !important;
    height: max-content !important;
    padding-top: 10px;

    audio {
      width: 100%;
      height: revert !important;
    }
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
