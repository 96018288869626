@import "@/styles/base/responsive";

.c-layout {
  display: flex;
  height: 100vh;

  .c-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    box-sizing: border-box;

    .content-inner {
      flex: 1;
      overflow-y: auto;
      box-sizing: border-box;
      padding: 16px 24px 0 24px;

      @include Responsive(LG) {
        padding: 16px 44px 0 44px;
      }
    }
  }
}

.c-drawer-sidebar {
  &__body {
    padding: 0 !important;

    .c-sidebar {
      display: flex !important;
    }
  }
}
