@import "@/styles/base/responsive";

.p-ticket-register {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .title {
    font-size: 30px;
    font-weight: 600;
    text-align: center;
  }

  .remaining-time {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
  }

  .ticket-form {
    width: 100%;
    margin-bottom: 40px;

    @include Responsive(LG) {
      width: 700px;
    }

    .body {
      margin-top: 20px;
      padding: 10px;
      min-height: 222px;
      border-radius: 6px;
      border: 2px solid rgba(5, 5, 5, 0.06);
    }
    .footer {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
    }
  }

  .open-time {
    color: red;
    font-weight: 600;
    font-size: 16px;
  }
}
