@import "@/styles/base/responsive";

.c-header {
  border-bottom: var(--border-layout-1);
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;

  .left {
    padding-right: 12px;
    .show-menu {
      cursor: pointer;

      @include Responsive(LG) {
        display: none;
      }

      svg {
        width: 22px;
        height: 22px;

        &:hover {
          color: var(--color-primary);
        }
      }
    }
  }

  .right {
    display: flex;
    align-items: center;
    gap: 12px;

    .account {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      overflow: hidden;
      flex-shrink: 0;
      border: var(--border-2);
      box-sizing: border-box;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .notification {
      width: 22px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
